import {useRouter} from 'next/router';
import React from 'react';

import * as UserFacade from '@app/facade/user';
import useUser from '@app/hooks/user/useUser';
import {useQueryState} from '@app/misc/query-state';

interface AnonymousOnlyProps {
  loggedInUserRedirectionPath?: string;
  children: JSX.Element;
}

// FIXME: https://riiid-pioneer.atlassian.net/browse/TN-2600
const AnonymousOnly = ({children, loggedInUserRedirectionPath}: AnonymousOnlyProps) => {
  const isRedirectedRef = React.useRef(false);
  const queryState = useQueryState();
  const {data: user, isFetching, isSuccess} = useUser();
  const isAnonymousUser = UserFacade.getIsUserAnonymous(user);
  const router = useRouter();
  const isAnonymous = isSuccess && isAnonymousUser;
  const isNotAnonymous = !isAnonymous;

  React.useEffect(() => {
    if (isFetching || isRedirectedRef.current) {
      return;
    }

    if (isNotAnonymous) {
      router.replace({
        pathname: loggedInUserRedirectionPath,
        query: queryState.toQuery(),
      });
    }

    isRedirectedRef.current = true;
  }, [router, isNotAnonymous, queryState, isFetching, loggedInUserRedirectionPath]);

  return isAnonymous ? children : null;
};

const AnonymousOnlyGuard = ({children, loggedInUserRedirectionPath = '/'}: AnonymousOnlyProps) => {
  return <AnonymousOnly loggedInUserRedirectionPath={loggedInUserRedirectionPath}>{children}</AnonymousOnly>;
};

export default AnonymousOnlyGuard;
