import React from 'react';
import {css} from '@emotion/react';

import {FRAME_LAYOUT_BOTTOM_Z_INDEX} from '@app/constants/zIndex';

export interface FrameLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  bottomArea?: React.ReactNode;
  topNavBar?: React.ReactNode;
  children?: React.ReactNode;
}

const FrameLayout = React.forwardRef<HTMLDivElement, FrameLayoutProps>(
  ({bottomArea, topNavBar, children, ...props}, ref) => {
    return (
      <div
        ref={ref}
        css={css`
          display: flex;
          flex-direction: column;

          height: 100%;
        `}
        {...props}>
        {topNavBar}
        <div
          className="frame-layout-content"
          css={css`
            flex: 1;
            position: relative;
            min-height: 0;
          `}>
          {children}
        </div>
        <div
          css={css`
            z-index: ${FRAME_LAYOUT_BOTTOM_Z_INDEX};
            width: 100%;
          `}>
          {bottomArea}
        </div>
      </div>
    );
  }
);

export default React.memo(FrameLayout);
